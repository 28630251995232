import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, Box, CardContent, Grid, Snackbar, Alert } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import HeaderImg from "../../../images/new-header.png";
import QrUnselectedImg from "../../../images/qr-unselected.png";
import QrSelectedImg from "../../../images/qr-selected.png";
import R2pUnselectedImg from "../../../images/r2p-unselected.png";
import R2pSelectedImg from "../../../images/r2p-selected.png";
import EasypaisaImg from "../../../images/easypaisa.png";
import CreditCardImg from "../../../images/credit-card.png";
import FawryImg from "../../../images/fawry.png";
import MWalletImg from "../../../images/mwallet.png";
import { cleanupMobile } from "&utils/cleanupMobile";
import ErrorScreen from "../error";
import WaitingModal from "../waitingModal";
import { RootState } from "&store/store";
import { fawryCheckoutActions } from "../egyptCheckout.slice";
import { servicesActions } from "&features/services/services.slice";
import styles from "../egyptCheckout.module.css";
import QrModal from "../qrModal";

type ReduxProps = ConnectedProps<typeof connector> & {
  sessionId: string;
};

const FawryCheckoutComponent = (props: ReduxProps & React.PropsWithChildren<{}>) => {
  const {
    sessionId,
    getSessionInfo,
    checkout3D,
    checkoutViaQr,
    checkoutViaFawryRef,
    getService,
    checkStatus,
    updateStatus,
    checkoutViaR2p,
  } = props;
  const [waitingModalOpen, setWaitingModalOpen] = useState(false);
  const [referanceNumber, setReferanceNumber] = useState("");
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [serviceProvider, setServiceProvider] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("waiting");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [qrImage, setQrImage] = useState("");
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [error, setError] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState({
    paymentProvider: "Credit Card",
    walletProvider: "qr",
    debitMobileWalletNo: "",
    mobileNumber: "",
    email: "",
    cardNumber: "",
    nameOnCard: "",
    cardExpiry: "",
    cvv: "",
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const mwalletOptions = ["qr", "r2p"];
  const handleWaitingModalClose = () => {
    setWaitingModalOpen(false);
  };

  const handleQrModalClose = () => {
    setQrModalOpen(false);
  };

  const validation = Yup.object().shape({
    paymentProvider: Yup.string().required("Please select payment provider"),
    mobileNumber: Yup.string().trim().min(10).required("Please provide valid mobile number"),
    nameOnCard: Yup.string().trim().min(3).required("Please provide valid Name"),
    cardNumber: Yup.string()
      .trim()
      .when("paymentProvider", {
        is: "Credit Card",
        then: Yup.string()
          .matches(
            /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/,
            "Invalid card number"
          )
          .required("Please provide valid card number"),
      }),
    cardExpiry: Yup.string()
      .trim()
      .when("paymentProvider", {
        is: "Credit Card",
        then: Yup.string()
          .required("Expiry is required")
          .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Expiry date must be in the format MM/YY"),
      }),
    cvv: Yup.string()
      .trim()
      .when("paymentProvider", {
        is: "Credit Card",
        then: Yup.string().min(3).max(3).required("Please provide valid CVV"),
      }),
    //debitMobileWalletNo: Yup.string()
    //  .trim()
    //  .when(["walletProvider", "paymentProvider"], {
    //    is: (wp,pp)=> wp || pp,
    //    then: Yup.string().min(10).required("Please provide valid debit mobile wallet number"),
    //    otherwise: Yup.string().notRequired()
    //  }),

    email: Yup.string().email().required("Please provide valid email"),
    //email: Yup.string()
    //  .required("Please provide valid email")
    //  .matches(
    //    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //    "Invalid email address"
    //  ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validation,
  });

  useEffect(() => {
    (async () => {
      if (!sessionId) return setError("Session ID not provided");
      try {
        const { payload } = await getSessionInfo(sessionId);

        if (payload.errors) {
          setError(payload.errors[0].message);
        }
        const { payload: service } = await getService(payload.serviceId);

        const cleanedMobileNumber = cleanupMobile(payload.msisdn);
        setData((prevState) => ({
          ...prevState,
          mobileNumber: cleanedMobileNumber,
          debitMobileWalletNo: cleanedMobileNumber,
          paymentProvider: payload?.paymentMethods?.[0]
        }));
        setServiceProvider(service.serviceProvider);
        setSessionInfo(payload);
      } catch (e) {
        console.log("error :", e);
      }
    })();
  }, [sessionId, getSessionInfo, getService]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000 ? setMobileView(true) : setMobileView(false);
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handlePaymentProviders = (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("paymentProvider", val);
  };

  const handleMobileChange = (e, formik) => {
    const val = e.target.value.replace(/\s/g, "").trim();
    formik.setFieldValue("mobileNumber", val);
  };

  const handleDebitMobileChange = (e, formik) => {
    const val = e.target.value.replace(/\s/g, "").trim();
    formik.setFieldValue("debitMobileWalletNo", val);
  };

  const handleWalletProviders = (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("walletProvider", val);
  };

  const handleCardChange = (e, formik) => {
    const val = e.target.value.replace(/\s/g, "").trim();
    formik.setFieldValue("cardNumber", val);
  };

  const handleExpiryChange = (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("cardExpiry", val);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  const renderMwalletImage = (val, walletProvider) => {
    if (val === "qr" && walletProvider === "qr") return QrSelectedImg;
    if (val === "qr" && walletProvider === "r2p") return QrUnselectedImg;
    if (val === "r2p" && walletProvider === "r2p") return R2pSelectedImg;
    if (val === "r2p" && walletProvider === "qr") return R2pUnselectedImg;
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlertOpen(false);
  };

  const handleSubmit = async (vals) => {
    const { orderId, serviceId, redirectUrl } = sessionInfo;

    if (vals.paymentProvider === "Credit Card") {
      const [month, year] = vals.cardExpiry.split("/");
      const params = {
        sid: serviceId,
        paymentMethod: "CARD",
        cardNumber: parseInt(vals.cardNumber),
        customerName: vals.nameOnCard,
        cvv: parseInt(vals.cvv),
        customerMobile: vals.mobileNumber,
        cardExpiryMonth: parseInt(month),
        cardExpiryYear: parseInt(year),
        returnUrl: `https://wpgateway.net/checkout/mena/${serviceId}/${orderId}`,
        sessionId,
        serviceId,
        orderId,
      };
      setWaitingModalOpen(true);
      try {
        const { payload } = await checkout3D(params);
        console.log('payload', payload)
        const redirectLink = payload.redirectLink;
        window.location.href = redirectLink;

        setWaitingModalOpen(false);
        return;
      } catch (e) {}
    }
    if (vals.paymentProvider === "MWallet") {
      setWaitingModalOpen(true);
      const params: any = {
        sid: serviceId,
        paymentMethod: "MWALLET",
        customerName: vals.nameOnCard,
        customerMobile: vals.mobileNumber,
        returnUrl: `https://wpgateway.net/checkout/mena/${serviceId}/${orderId}`,
        sessionId,
        serviceId,
        orderId,
      };
      if (vals.walletProvider === "qr") {
        const { payload } = await checkoutViaQr(params);
        if (payload.statusCode === 200) {
          setReferanceNumber(payload.merchantRefNumber);
          setQrImage(payload.walletQr);
          setQrModalOpen(true);
          const timer = setTimeout(async () => {
            const redirectStr = `?status=PENDING&orderId=${orderId}`;
            window.location.href = redirectUrl + redirectStr;
          }, 15000);
          return () => clearInterval(timer);
        }
        setWaitingModalOpen(false);
      } else {
        params.debitMobileWalletNo = vals.debitMobileWalletNo;
        const { payload } = await checkoutViaR2p(params);
        if (payload.statusCode === 200) {
          setReferanceNumber(payload.merchantRefNumber);

          const timer = setTimeout(async () => {
            const { payload: statusResp } = await checkStatus({ referanceNumber: payload.merchantRefNumber });
            const parsed = statusResp;
            parsed.serviceId = serviceId;
            parsed.orderId = orderId;
            await updateStatus(parsed);
            if (parsed.orderStatus === "PAID") {
              let redirectStr = `?status=SUCCESS&orderId=${orderId}&amount=${parsed.paymentAmount}`;
              if (redirectUrl) {
                window.location.href = redirectUrl + redirectStr;
              }
            } else {
              let redirectStr = `?status=PENDING&orderId=${orderId}&amount=${parsed.paymentAmount}`;
              if (redirectUrl) {
                window.location.href = redirectUrl + redirectStr;
              }
            }
          }, 15000);
          return () => clearInterval(timer);
        }
        setWaitingModalOpen(false);
      }
    }
    if (vals.paymentProvider === "FawryRef") {
      setWaitingModalOpen(true);
      const params: any = {
        sid: serviceId,
        paymentMethod: "PAYATFAWRY",
        customerName: vals.nameOnCard,
        customerMobile: vals.mobileNumber,
        debitMobileWalletNo: vals.debitMobileWalletNo,
        returnUrl: `https://wpgateway.net/checkout/mena/${serviceId}/${orderId}`,
        sessionId,
        serviceId,
        orderId,
      };

      const { payload } = await checkoutViaFawryRef(params);
      if (payload.statusCode === 200) {
        setReferanceNumber(payload.merchantRefNumber);

        const timer = setTimeout(async () => {
          const { payload: statusResp } = await checkStatus({ referanceNumber: payload.merchantRefNumber });
          const parsed = statusResp;
          parsed.serviceId = serviceId;
          parsed.orderId = orderId;
          await updateStatus(parsed);
          if (parsed.orderStatus === "PAID") {
            let redirectStr = `?status=SUCCESS&orderId=${orderId}&amount=${parsed.paymentAmount}`;
            if (redirectUrl) {
              window.location.href = redirectUrl + redirectStr;
            }
          } else {
            let redirectStr = `?status=PENDING&orderId=${orderId}&amount=${parsed.paymentAmount}&referenceNumber=${parsed.referenceNumber}`;
            if (redirectUrl) {
              window.location.href = redirectUrl + redirectStr;
            }
          }
        }, 15000);
        return () => clearInterval(timer);
      }
      setWaitingModalOpen(false);
    }
  };

  const renderMobile = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Header Image*/}
                <Box sx={{ width: matches ? "381px" : "297px", height: "95px" }}>
                  <img
                    src={HeaderImg}
                    alt="walee payment"
                    style={{
                      width: matches ? "381px" : "297px",
                      height: "95px",
                    }}
                  />
                </Box>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Payment Type
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>*/}
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={2}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack ? `${styles.priceBoxActive}` : styles.priceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              flexDirection: "column",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "mwallet"
                                  ? MWalletImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardImg
                                  : FawryImg
                              }
                              alt={pack}
                              className={styles.paymentImage}
                            />
                            <Box sx={{ fontSize: "12px" }}>
                              {pack.toLowerCase() === "mwallet"
                                ? "Virtual Wallet"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit Card"
                                : pack.toLowerCase() === "fawryref"
                                ? "Via Ref"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile Number
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      موبائل نمبر
                    </Box>*/}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.mobileNumber ? "1px solid red" : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>+20</Box>

                    <InputMask
                      placeholder="117 123 4567"
                      mask="999 999 99999"
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="117 123 4567"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.mobileNumber}</Box>
                </Box>

                {/* Email Address */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Address
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      ای میل
                    </Box>*/}
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={formik.errors.email ? styles.emailInputError : styles.emailInput}
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.email}</Box>
                </Box>

                {/* Name input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {formik.values.paymentProvider === "Credit Card" ? "Name on card" : "Account Holder Name"}
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      شناختی کارڈ نمبر
                    </Box>*/}
                  </Box>

                  <input
                    value={formik.values.nameOnCard}
                    onChange={formik.handleChange("nameOnCard")}
                    placeholder="Muhammad Ali"
                    className={formik.errors.nameOnCard ? styles.emailInputError : styles.emailInput}
                  />

                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.nameOnCard}</Box>
                </Box>

                {/* Debit Mobile Wallet No input */}
                {(formik.values.walletProvider === "r2p" || formik.values.paymentProvider === "FawryRef") && (
                  // <Box
                  //    sx={{
                  //      display: "flex",
                  //      gap: 1,
                  //      flexDirection: "column",
                  //      marginTop: "1rem",
                  //    }}
                  //  >
                  //    <Box
                  //      sx={{
                  //        display: "flex",
                  //        justifyContent: "space-between",
                  //        alignItems: "center",
                  //      }}
                  //    >
                  //      <Box
                  //        sx={{
                  //          color: "#313131",
                  //          fontSize: "12px",
                  //          fontWeight: "bold",
                  //        }}
                  //      >
                  //       Debit Mobile Wallet No
                  //      </Box>
                  //      {/*<Box
                  //        sx={{
                  //          color: "#313131",
                  //          fontSize: "14px",
                  //          fontWeight: "bold",
                  //        }}
                  //      >
                  //        شناختی کارڈ نمبر
                  //      </Box>*/}
                  //    </Box>

                  //      <input
                  //      value={formik.values.debitMobileWalletNo}
                  //      onChange={formik.handleChange("debitMobileWalletNo")}
                  //      placeholder="Debit Mobile Wallet No"
                  //      className={
                  //        formik.errors.debitMobileWalletNo
                  //          ? styles.emailInputError
                  //          : styles.emailInput
                  //      }
                  //    />
                  //    <Box sx={{ color: "red", fontSize: "10px" }}>
                  //      {formik.errors.debitMobileWalletNo}
                  //    </Box>
                  //  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Debit Mobile Wallet No
                      </Box>
                      {/*<Box
                  sx={{
                    color: "#313131",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  موبائل نمبر
                </Box>*/}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "1rem",
                        width: matches ? "342px" : "250px",
                        border: formik.errors.debitMobileWalletNo ? "1px solid red" : "1px solid #dddddd",
                        borderRadius: "7px",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box sx={{ fontWeight: "bold" }}>+20</Box>
                      <InputMask
                        placeholder="117 123 4567"
                        mask="999 999 99999"
                        maskChar=" "
                        value={formik.values.debitMobileWalletNo}
                        onChange={(e) => handleDebitMobileChange(e, formik)}
                      >
                        {(inputProps) => (
                          <input
                            type="tel"
                            className={styles.inputField}
                            placeholder="117 123 4567"
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                        )}
                      </InputMask>
                    </Box>
                    <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.debitMobileWalletNo}</Box>
                  </Box>
                )}

                {/*Card Number Input*/}
                {formik.values.paymentProvider === "Credit Card" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Card number
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "1rem",
                        width: matches ? "342px" : "250px",
                        border: formik.errors.cardNumber ? "1px solid red" : "1px solid #dddddd",
                        borderRadius: "7px",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <InputMask
                        placeholder="4111 1111 1111 1111"
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        value={formik.values.cardNumber}
                        onChange={(e) => handleCardChange(e, formik)}
                      >
                        {(inputProps) => (
                          <input
                            type="tel"
                            className={styles.inputField}
                            placeholder="4111 1111 1111 1111"
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                        )}
                      </InputMask>
                    </Box>
                  </Box>
                )}
                {/*Card Validitiy Input*/}
                {formik.values.paymentProvider === "Credit Card" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Valid thru
                      </Box>
                      <InputMask
                        mask="99/99"
                        value={formik.values.cardExpiry}
                        onChange={(e) => handleExpiryChange(e, formik)}
                        name="cardExpiry"
                      >
                        {(inputProps) => <OutlinedInput {...inputProps} placeholder="MM/YY" sx={{ height: "3rem" }} />}
                      </InputMask>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        CVV
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "1rem",
                          //width: matches ? "342px" : "250px",
                          border: formik.errors.cvv ? "1px solid red" : "1px solid #dddddd",
                          borderRadius: "7px",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <InputMask
                          placeholder="CVV"
                          mask="999"
                          maskChar=" "
                          value={formik.values.cvv}
                          onChange={(e) => formik.setFieldValue("cvv", e.target.value)}
                        >
                          {(inputProps) => (
                            <input
                              type="tel"
                              className={styles.inputField}
                              placeholder="CVV"
                              onKeyDown={(e) => handleKeyDown(e)}
                            />
                          )}
                        </InputMask>
                      </Box>
                    </Box>
                  </Box>
                )}
                {formik.values.paymentProvider === "MWallet" && (
                  <Grid container spacing={2}>
                    {mwalletOptions?.map((pack, index) => (
                      <Grid item lg={6} md={6} sm={12} key={index}>
                        <div
                          className={
                            formik.values.walletProvider === pack
                              ? `${styles.priceBoxWalletActive}`
                              : styles.priceBoxWallet
                          }
                        >
                          <label htmlFor={pack}>
                            <input
                              type="radio"
                              value={pack}
                              name="walletProvider"
                              id={pack}
                              onChange={(e) => handleWalletProviders(e, formik)}
                              hidden
                            />
                            <Box
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={renderMwalletImage(pack, formik.values.walletProvider)}
                                alt={pack}
                                className={styles.paymentImage}
                              />
                              <Box sx={{ fontSize: "12px" }}>
                                {pack.toLowerCase() === "qr" ? "Via QR" : pack.toLowerCase() === "r2p" ? "OTP" : pack}
                              </Box>
                            </Box>
                          </label>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button type="submit" className={styles.primaryCta} onClick={() => formik.handleSubmit()}>
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    We don't have any cash refund policy
                  </Box>
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                waitingIcon={false}
                referenceNumber={
                  referanceNumber
                    ? JSON.stringify({
                        text: "Please type the reference number in your Fawry app",
                        number: referanceNumber,
                      })
                    : null
                }
              />
              <QrModal
                open={qrModalOpen}
                handleClose={handleQrModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                qrCode={qrImage}
              />
            </Card>
          </Box>
        )}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Header Image*/}
                <Box sx={{ width: matches ? "381px" : "297px", height: "95px" }}>
                  <img
                    src={HeaderImg}
                    alt="walee payment"
                    style={{
                      width: matches ? "381px" : "297px",
                      height: "95px",
                    }}
                  />
                </Box>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Payment Type
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>*/}
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={2}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack ? `${styles.priceBoxActive}` : styles.priceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              flexDirection: "column",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "mwallet"
                                  ? MWalletImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardImg
                                  : FawryImg
                              }
                              alt={pack}
                              className={styles.paymentImage}
                            />
                            <Box sx={{ fontSize: "12px" }}>
                              {pack.toLowerCase() === "mwallet"
                                ? "Virtual Wallet"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit Card"
                                : pack.toLowerCase() === "fawryref"
                                ? "Via Ref"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile Number
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      موبائل نمبر
                    </Box>*/}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.mobileNumber ? "1px solid red" : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>+20</Box>

                    <InputMask
                      placeholder="117 123 4567"
                      mask="999 999 99999"
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="117 123 4567"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.mobileNumber}</Box>
                </Box>

                {/* Email Address */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Address
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      ای میل
                    </Box>*/}
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={formik.errors.email ? styles.emailInputError : styles.emailInput}
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.email}</Box>
                </Box>

                {/* Name input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {formik.values.paymentProvider === "Credit Card" ? "Name on card" : "Account Holder Name"}
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      شناختی کارڈ نمبر
                    </Box>*/}
                  </Box>

                  <input
                    value={formik.values.nameOnCard}
                    onChange={formik.handleChange("nameOnCard")}
                    placeholder="Muhammad Ali"
                    className={formik.errors.nameOnCard ? styles.emailInputError : styles.emailInput}
                  />

                  <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.nameOnCard}</Box>
                </Box>

                {/* Debit Mobile Wallet No input */}
                {(formik.values.walletProvider === "r2p" || formik.values.paymentProvider === "FawryRef") && (
                  // <Box
                  //    sx={{
                  //      display: "flex",
                  //      gap: 1,
                  //      flexDirection: "column",
                  //      marginTop: "1rem",
                  //    }}
                  //  >
                  //    <Box
                  //      sx={{
                  //        display: "flex",
                  //        justifyContent: "space-between",
                  //        alignItems: "center",
                  //      }}
                  //    >
                  //      <Box
                  //        sx={{
                  //          color: "#313131",
                  //          fontSize: "12px",
                  //          fontWeight: "bold",
                  //        }}
                  //      >
                  //       Debit Mobile Wallet No
                  //      </Box>
                  //      {/*<Box
                  //        sx={{
                  //          color: "#313131",
                  //          fontSize: "14px",
                  //          fontWeight: "bold",
                  //        }}
                  //      >
                  //        شناختی کارڈ نمبر
                  //      </Box>*/}
                  //    </Box>

                  //      <input
                  //      value={formik.values.debitMobileWalletNo}
                  //      onChange={formik.handleChange("debitMobileWalletNo")}
                  //      placeholder="Debit Mobile Wallet No"
                  //      className={
                  //        formik.errors.debitMobileWalletNo
                  //          ? styles.emailInputError
                  //          : styles.emailInput
                  //      }
                  //    />
                  //    <Box sx={{ color: "red", fontSize: "10px" }}>
                  //      {formik.errors.debitMobileWalletNo}
                  //    </Box>
                  //  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Debit Mobile Wallet No
                      </Box>
                      {/*<Box
                  sx={{
                    color: "#313131",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  موبائل نمبر
                </Box>*/}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "1rem",
                        width: matches ? "342px" : "250px",
                        border: formik.errors.debitMobileWalletNo ? "1px solid red" : "1px solid #dddddd",
                        borderRadius: "7px",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box sx={{ fontWeight: "bold" }}>+20</Box>
                      <InputMask
                        placeholder="117 123 4567"
                        mask="999 999 99999"
                        maskChar=" "
                        value={formik.values.debitMobileWalletNo}
                        onChange={(e) => handleDebitMobileChange(e, formik)}
                      >
                        {(inputProps) => (
                          <input
                            type="tel"
                            className={styles.inputField}
                            placeholder="117 123 4567"
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                        )}
                      </InputMask>
                    </Box>
                    <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.debitMobileWalletNo}</Box>
                  </Box>
                )}

                {/*Card Number Input*/}
                {formik.values.paymentProvider === "Credit Card" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Card number
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "1rem",
                        width: matches ? "342px" : "250px",
                        border: formik.errors.cardNumber ? "1px solid red" : "1px solid #dddddd",
                        borderRadius: "7px",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <InputMask
                        placeholder="4111 1111 1111 1111"
                        mask="9999 9999 9999 9999"
                        maskChar=" "
                        value={formik.values.cardNumber}
                        onChange={(e) => handleCardChange(e, formik)}
                      >
                        {(inputProps) => (
                          <input
                            type="tel"
                            className={styles.inputField}
                            placeholder="4111 1111 1111 1111"
                            onKeyDown={(e) => handleKeyDown(e)}
                          />
                        )}
                      </InputMask>
                    </Box>
                    <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.cardNumber}</Box>
                  </Box>
                )}
                {/*Card Validitiy Input*/}
                {formik.values.paymentProvider === "Credit Card" && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      marginTop: "2rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Valid thru
                      </Box>
                      <InputMask
                        mask="99/99"
                        value={formik.values.cardExpiry}
                        onChange={(e) => handleExpiryChange(e, formik)}
                        name="cardExpiry"
                      >
                        {(inputProps) => (
                          <Box>
                            <OutlinedInput {...inputProps} placeholder="MM/YY" sx={{ height: "3rem" }} />
                            <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.cardExpiry}</Box>
                          </Box>
                        )}
                      </InputMask>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#313131",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        CVV
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          padding: "1rem",
                          //width: matches ? "342px" : "250px",
                          border: formik.errors.cvv ? "1px solid red" : "1px solid #dddddd",
                          borderRadius: "7px",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <InputMask
                          placeholder="CVV"
                          mask="999"
                          maskChar=" "
                          value={formik.values.cvv}
                          onChange={(e) => formik.setFieldValue("cvv", e.target.value)}
                        >
                          {(inputProps) => (
                            <input
                              type="tel"
                              className={styles.inputField}
                              placeholder="CVV"
                              onKeyDown={(e) => handleKeyDown(e)}
                            />
                          )}
                        </InputMask>
                      </Box>
                      <Box sx={{ color: "red", fontSize: "10px" }}>{formik.errors.cvv}</Box>
                    </Box>
                  </Box>
                )}
                {formik.values.paymentProvider === "MWallet" && (
                  <Grid container spacing={2}>
                    {mwalletOptions?.map((pack, index) => (
                      <Grid item lg={6} md={6} sm={12} key={index}>
                        <div
                          className={
                            formik.values.walletProvider === pack
                              ? `${styles.priceBoxWalletActive}`
                              : styles.priceBoxWallet
                          }
                        >
                          <label htmlFor={pack}>
                            <input
                              type="radio"
                              value={pack}
                              name="walletProvider"
                              id={pack}
                              onChange={(e) => handleWalletProviders(e, formik)}
                              hidden
                            />
                            <Box
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                gap: 2,
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={renderMwalletImage(pack, formik.values.walletProvider)}
                                alt={pack}
                                className={styles.paymentImage}
                              />
                              <Box sx={{ fontSize: "12px" }}>
                                {pack.toLowerCase() === "qr" ? "Via QR" : pack.toLowerCase() === "r2p" ? "OTP" : pack}
                              </Box>
                            </Box>
                          </label>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button type="submit" className={styles.primaryCta} onClick={() => formik.handleSubmit()}>
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    We don't have any cash refund policy
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    ہماری کیش ریفندپالیسی نہیں ہے
                  </Box>*/}
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                waitingIcon={false}
                referenceNumber={
                  referanceNumber
                    ? JSON.stringify({
                        text: "Please type the reference number in your Fawry app",
                        number: referanceNumber,
                      })
                    : null
                }
              />
              <QrModal
                open={qrModalOpen}
                handleClose={handleQrModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                qrCode={qrImage}
              />
            </Card>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {error && <ErrorScreen error={error} matches={matches} />}
      {mobileView ? renderMobile() : renderDesktop()}
      <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSessionInfo: fawryCheckoutActions.getSessionInfo,
  checkout3D: fawryCheckoutActions.checkoutVia3D,
  checkoutViaQr: fawryCheckoutActions.checkoutViaQr,
  checkoutViaR2p: fawryCheckoutActions.checkoutViaR2p,
  checkoutViaFawryRef: fawryCheckoutActions.checkoutViaFawryRef,
  checkStatus: fawryCheckoutActions.checkStatus,
  updateStatus: fawryCheckoutActions.updateStatus,
  getService: servicesActions.getServiceBySid,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const FawryCheckoutComponentRedux = connector(FawryCheckoutComponent);

export { FawryCheckoutComponentRedux as FawryCheckoutComponent };
